import React, { useCallback } from 'react';
import { useQuery } from '@apollo/react-hooks';
import CategoryContentQuery from '../../ContentEditor/CategoryContentQuery.gql';
import { useInView } from 'react-intersection-observer';
import { styled } from 'linaria/react';
import { theme } from '../../Theme';
import { BasicButton } from '../../ContentEditor/BasicButton';
import { useLocation } from 'react-router';
import { ContentRenderer } from '@jetshop/ui/ContentRenderer'; // Se till att detta är rätt importväg
import { footerComponents } from '../../ContentEditor/ContentEditorComponents'; // Kontrollera rätt importväg

const MembersClubWrapper = styled('div')`
  background: ${theme.colors.newPrimary};
  color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100vw;
  margin: auto;
  padding: 60px 40px;
  height: 100%;

  ${theme.below.lg} {
    padding: 40px 0;
    .usp-wrapper {
      flex-direction: column;
      align-items: flex-start;
    }

    h2 {
      font-size: 22px;
    }
  }

  h2 {
    text-align: center;
    margin-bottom: 16px;
    color: #000;
    text-align: center;
    font-family: ${theme.fonts.secondary};
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 41px; /* 146.429% */
  }

  .usp-wrapper {
    display: flex;
    width: 100%;
    justify-content: center;

    li {
      padding-right: 20px;
      margin-left: 20px;
      color: #000;
      text-align: center;
      font-family: ${theme.fonts.primary};
      font-size: 16px;
      font-style: normal;
      font-weight: 330;
      line-height: 1.5;
    }
  }

  .button-wrapper {
    margin-top: 30px;
  }
`;

const MembersClubFooter = React.memo(() => (
  <MembersClubWrapper>
    <div>
      <h2>Bli medlem i vår kundeklubb</h2>
      <ul className="usp-wrapper">
        <li>15% bonus på alle ordinære varer </li>
        <li>Fri frakt over 199,- </li>
        <li>Digitale kvitteringer </li>
        <li>Eksklusive og personlige tilbud </li>
      </ul>
    </div>
    <div className="button-wrapper">
      <BasicButton
        title={'Bli med nå'}
        text={'Bli med nå'}
        theme={'WHITE'}
        link={'/login'}
      />
    </div>
  </MembersClubWrapper>
));

const Footer = () => {
  const categoryResult = useQuery(CategoryContentQuery, {
    variables: {
      id: 154
    }
  });

  const location = useLocation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1
  });

  const shouldShowMembersClubFooter = useCallback(() => {
    const path = location?.pathname;
    return !(
      path.includes('my-pages') ||
      path.includes('login') ||
      path.includes('signup')
    );
  }, [location]);

  return (
    <div ref={ref}>
      {shouldShowMembersClubFooter() && inView && <MembersClubFooter />}
      {categoryResult?.data?.category?.data?.items && (
        <ContentRenderer
          items={categoryResult?.data?.category?.data?.items}
          components={footerComponents}
        />
      )}
    </div>
  );
};

export default React.memo(Footer);
